<template>
  <div>
    <Grid :key="key"
          :service="service"
          route_name="questionnaire_correctors">
           <template v-slot:actions="data">
            <feather-icon icon="EyeIcon" svgClasses="w-6 h-6 text-primary" @click="openDashboard(data)"/>
          </template>
    </Grid>
  </div>
</template>

<script>
import QuestionnaireCorrectorService from '@/services/api/QuestionnaireCorrectorService'

export default {
  data: () => ({
    selected: [],
    service: null
  }),
  methods: {
    openDashboard(data) {
      console.log('row...', data)
      this.$router.push(`/questionnaire_correctors/${data.row.id}/dashboard`)
    }
  },
  beforeMount() {
    this.service = QuestionnaireCorrectorService.build(this.$vs)
  }
}
</script>
